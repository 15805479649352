<template>
  <div class="mt-4 md:mt-24 lg:mt-44 flex flex-col justify-center items-center">
    <div class="w-max relative">
      <div class="rounded-full bg-greyishWhite p-24"></div>
      <img
        class="h-32 rounded-full absolute top-8 left-8"
        rel="prefetch"
        src="https://www.its.ac.id/international/wp-content/uploads/sites/66/2020/02/blank-profile-picture-973460_1280-1-300x300.jpg"
        alt="profile picture"
      />
    </div>
    <div class="mt-6">
      <div class="font-proximaMedium" v-if="data">
        <h1 class="text-2xl">
          Invitation from
          {{ name }}
        </h1>
        <p class="mt-2 my-6 text-sm">
          {{ name }}
          invited you to interact with
          {{ petName }}'s Pet Record, to continue please create an account.
        </p>
      </div>
      <div
        class="w-max mx-auto mt-8 text-white bg-primary px-10 py-3 rounded-md cursor-pointer"
        @click="routeTo"
      >
        Get Started
      </div>
    </div>
  </div>
</template>

<script>
import MailIcon from "../icons/MailIcon.vue";
import FacebookIcon from "../icons/FacebookIcon.vue";
import { privateGuestByUrl } from "../../services/sharingService";

export default {
  components: {
    MailIcon,
    FacebookIcon,
  },
  data() {
    return {
      text: "https://perpetual.guest.com/farhadahmed",
      data: {},
      name: "",
      petName: "",
    };
  },
  async created() {
    await privateGuestByUrl(this.$route.params["codeinvitation"])
      .then((response) => {
        this.data = response.data;
        this.name =
          this.data.invitingUserName.user_first_name.charAt(0).toUpperCase() +
          this.data.invitingUserName.user_first_name.slice(1);
        this.petName =
          this.data.petName.charAt(0).toUpperCase() +
          this.data.petName.slice(1);
        localStorage.setItem("ruleID", this.data.ruleID);
        localStorage.setItem("role", 2);
        console.log(response);
      })
      .catch((error) => {
        console.log(error.response);
      });
  },
  methods: {
    copyClipboard() {
      navigator.clipboard.writeText(this.text);
    },
    routeTo() {
      this.$router.push({ name: "PrimaryRegistration" });
    },
  },
};
</script>

<style>
</style>